import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import ReactQuill from 'react-quill';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';

import {
    OPEN_NOTIFICATION,
    OPEN_LOADING,
    CLOSE_LOADING 
   
} from '../../../actions/actions';

import '../../../main.scss';

import { postMessage, updateMessage } from './../../../service';
import store from '../../../store';

class PopupMessage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: null, 
            text: this.props.text,
            title: this.props.title,
            titleError: false,
            textError: false
        }

        this.changeInput = this.changeInput.bind(this);
        this.changeTitle = this.changeTitle.bind(this);
        this.createMessage = this.createMessage.bind(this);
        this.updateMessage  = this.updateMessage.bind(this);

    }

      componentWillReceiveProps = (nextProps) => {
        this.setState({ title: ''});
        this.setState({ text: ''});
        this.setState({ titleError: false});
        this.setState({ textError: false});
      }

    createMessage() {
            if(!this.state.title || this.state.title.length === 0) {
                this.setState({ titleError: true });
            return;
            }

            if(!this.state.text || this.state.text.length === 0)  {
                this.setState({ textError: true });
                return;
        
        } else {
            
            store.dispatch({type: OPEN_LOADING, text: 'Creating message', open: true});
        postMessage({ text: this.state.text, title: this.state.title }).then(result => {

            const { id, text, title, q } = result.data.message;

            this.props.addMessage({id, text, title, q});

            store.dispatch({type: CLOSE_LOADING, text: 'Creating message', open: false});
        })
    }
    }

    updateMessage() {
        const id = this.props.id,
            text = this.state.text ? this.state.text : this.props.text,
            title = this.state.title ? this.state.title: this.props.title

            if(!title) {
                this.setState({ titleError: true });
                return;
            } 
            else {

        store.dispatch({type: OPEN_LOADING, text: 'Updating message', open: true});
        updateMessage(id, text, title).then(result => {
            this.props.updateMessage(id, text, title)
            this.props.handleClose();
            store.dispatch({type: CLOSE_LOADING, text: 'Creating message', open: false});
        }).catch(err => {
            store.dispatch({type: OPEN_LOADING, text: `Can't update the message`, open: true});

            setTimeout(() => {
                store.dispatch({type: CLOSE_LOADING, text: 'Creating message', open: false});
            }, 2000)
        })
    }
    }

    changeInput(text) {
        this.setState({ text });
        this.setState({ textError: false });
    }

    changeTitle(title) {
        this.setState({ title });
        this.setState({ titleError: false });
    }

    render() {
        const { id, text, title } = this.props;
        const titleError = this.state.titleError;
        
        return(
            !id ?
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="form-dialog-title"
                classes={{
                    paper: 'visible-item',
                  }}
                >
                <DialogTitle id="form-dialog-title">Add new message</DialogTitle>
                <DialogContent style={style.content} className='vissible-item'>

                <TextField
                    variant="outlined"
                    size="small"
                    label="Heading"
                    margin="small"
                    error={titleError}
                    className='input--long'
                    defaultValue={title}
                    onChange={(e) => this.changeTitle(e.target.value)}
                    
                />

                    <ReactQuill style={{height: '250px', marginTop: '18px'}}
                        modules={Editor.modules}
                        formats={Editor.formats}
                        theme={"snow"}
                        onChange={this.changeInput}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose} color="primary">
                    Cancel
                    </Button>
                    <Button onClick={this.createMessage} color="primary">
                    Add message
                    </Button>
                </DialogActions>
            </Dialog>
            : 
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="form-dialog-title"
                classes={{
                    paper: 'visible-item',
                  }}
                >
                <DialogTitle id="form-dialog-title">Edit message</DialogTitle>
                <DialogContent style={style.content} className='vissible-item'>

                <TextField
                    variant="outlined"
                    size="small"
                    label="Heading"
                    margin="small"
                    className="input--long"
                    defaultValue={title}
                    error={titleError}
                    onChange={(e) => this.changeTitle(e.target.value)}
                />

                    <ReactQuill style={{height: '250px', marginTop: '18px'}}
                        modules={Editor.modules}
                        formats={Editor.formats}
                        theme={"snow"}
                        onChange={this.changeInput}
                        defaultValue={text}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose} color="primary">
                    Cancel
                    </Button>
                    <Button onClick={this.updateMessage} color="primary">
                    Update
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default PopupMessage;

const style = {
    content: {
        width: '550px',
        height: '350px',
        minheight: '270px',
        overflow: 'visible' 
    }
}

const Editor = {
    modules: {
        toolbar: [
          [{'header': []}, {'color': []}], // , {'color': []}, {'background': []}
          ['bold', 'italic', 'underline', 'blockquote'],
          [{'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link'],
          ['clean']
        ],
      },
    
      formats: [
        'header',
        'color',
        // 'background',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link'
      ],
}