import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import ReactQuill from 'react-quill';
import { withStyles } from '@material-ui/core/styles';

import {
    OPEN_NOTIFICATION,
    OPEN_LOADING,
    CLOSE_LOADING 
   
} from '../../../actions/actions';

import { postMessage, updateMessage } from './../../../service';
import store from '../../../store';

class PopupScript extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: null, 
            text: this.props.text,
            title: this.props.title
        }

        this.comfirmAction = this.comfirmAction.bind(this);
        this.changeInput = this.changeInput.bind(this);
    }

    changeInput(text) {
        this.setState({ text });
    }

    comfirmAction() {
        const text = this.state.text;
        const name = this.props.selectedScript;

        this.props.comfirm(name, text);
        this.props.handleClose();
    }

    render() {

        const { text } = this.state;
        
        return(
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="form-dialog-title"
                classes={{
                    paper: 'visible-item',
                  }}
                >
                <DialogTitle id="form-dialog-title">Script</DialogTitle>
                <DialogContent style={style.content} className='vissible-item'>
                    <ReactQuill style={{height: '250px'}}
                        modules={Editor.modules}
                        formats={Editor.formats}
                        theme={"snow"}
                        onChange={this.changeInput}
                        defaultValue={text}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose} color="primary">
                    Cancel
                    </Button>
                    <Button onClick={this.comfirmAction} color="primary">
                    Update
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default PopupScript;

const style = {
    content: {
        width: '550px',
        height: '300px',
        minheight: '270px',
        overflow: 'visible' 
    }
}

const Editor = {
    modules: {
        toolbar: [
          [{'header': []}, {'color': []}], // , {'color': []}, {'background': []}
          ['bold', 'italic', 'underline', 'blockquote'],
          [{'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link'],
          ['clean']
        ],
      },
    
      formats: [
        'header',
        'color',
        // 'background',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link'
      ],
}