import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Icon,
  MenuItem,
} from "@material-ui/core";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

// import GenerateNew from './generatePass';


import { OPEN_NOTIFICATION } from "../../../actions/actions";

import "./index.scss";

import { createUser, updateUser } from "./../../../service";
import { generate } from 'generate-password';

import store from "../../../store";

class PopupUser extends Component {
  constructor(props) {
    super(props);

    const user = this.props.user || {};

    console.log(user, "test user");

    this.state = {
      id: null,

      name: "",
      login: "",
      password: "",
      role: "",
      access: "",
      elite: false,
      pauseTeam: "",

    //   name: this.props.user.name,
    //   login: this.props.user.login,
    //   password: "",
    //   role: this.props.user.role,
    //   access: this.props.user.access,
    //   elite: false,

      isUpdate: false,

    };

    // this.changeInput = this.changeInput.bind(this);
    this.createUser = this.createUser.bind(this);
    this.updateUser = this.updateUser.bind(this);

    this.closePopup = this.closePopup.bind(this);
    this.inputChange = this.inputChange.bind(this);

    this.generatePass = this.generatePass.bind(this);
  }


//   componentWillReceiveProps = (nextProps) => {
//     this.setState({ name: ''});
//     this.setState({ login: ''});
//     this.setState({ password: ''});
//     this.setState({ role: ''});
//     this.setState({ access: ''});
//     this.setState({ elite: false});
// }


componentWillMount = () => {
    if(this.props.id) {
        this.setState({ name: this.props.user.name });
            this.setState({ login: this.props.user.login });
            this.setState({ role: this.props.user.role });
            this.setState({ access: this.props.user.access });
            this.setState({ elite: this.props.user.elite});
            this.setState({ pauseTeam: this.props.user.pauseTeam});
    }
  }

  generatePass() {
    const password = generate({
        length: 24,
        numbers: true
    })

    this.setState({
        password
    });
}

  inputChange(name, value) {
    const state = this.state;
    state[name] = value;
    this.setState(state);
  }

  createUser() {
    const { name, login, password, role, elite, access, pauseTeam } = this.state;

    const data = {
      name,
      login,
      pass: password,
      role,
      elite,
      access,
      pauseTeam
    };

    const newUser = {
      name,
      login,
      role,
      elite,
      access,
      pauseTeam
    };

    createUser(data)
      .then((resp) => {
        newUser.id = resp.data.id;
        this.props.createUser(newUser);
        this.props.handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  closePopup() {
    this.setState({ dirty: false });
    this.props.handleClose();
  }

    updateUser() {
        
        console.log('props', this.props)
        const id = this.props.id;

      const { 
        name,
        login,
        role,
        elite,
        access,
        pauseTeam
     } = this.state;


      const updatedUser = {
        name,
        login,
        role,
        elite,
        access,
        pauseTeam
      };

    
    updateUser(id, { ...updatedUser }).then(resp => {
          this.props.updateUser(id, updatedUser);
          this.props.handleClose();
        }).catch(err => console.log(err));
    }


  render() {
    const { id, user } = this.props;
    let { name, login, password, role, elite, access, pauseTeam } = this.state;
    // let { name, login, password, role, elite, access } = this.props.user;

    return !id ? (
      <Dialog
        open={this.props.open}
        onClose={this.closePopup}
        aria-labelledby="form-dialog-title"
        classes={{
          paper: "visible-item",
        }}
      >
        <DialogTitle id="form-dialog-title">Create a new user</DialogTitle>
        <DialogContent
          style={style.content}
          className="vissible-item user_popup_section"
        >
          <div className="add_new">
            <div className="fieldsGroup">
              <TextField
                label="Full name"
                variant="outlined"
                size="small"
                value={name}
                onChange={(event) =>
                  this.inputChange("name", event.target.value)
                }
              />
              <TextField
                label="Login name"
                variant="outlined"
                size="small"
                value={login}
                onChange={(event) =>
                  this.inputChange("login", event.target.value)
                }
              />
            </div>
            <Box sx={{ display: "flex", mt: "20px" }}>
              <TextField
                label="Password"
                size="small"
                className="passwordInput"
                value={password}
                onChange={(event) =>
                  this.inputChange("password", event.target.value)
                }
              />
              <Box sx={{ ml: "20px" }}>
                <Tooltip title="Generate Password" placement="bottom-end">
                  <Button
                    className="action -generate"
                    onClick={this.generatePass}
                    style={{ background: "#ecf1f9" }}
                    mini
                    aria-label="Generate"
                  >
                    <Icon style={{ color: "#2B61A5" }} className="offer_icon">
                      all_inclusive
                    </Icon>
                  </Button>
                </Tooltip>
              </Box>
            </Box>
            <Box sx={{ mt: "20px" }}>
              <FormControl size="small" fullWidth>
                <InputLabel id="role">Role</InputLabel>
                <Select
                  variant="outlined"
                  className="select"
                  onChange={(event) =>
                    this.inputChange("role", event.target.value)
                  }
                  value={role}
                  label="Role"
                  margin="normal"
                >
                  {["admin", "user"].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ mt: "20px" }}>
              <FormControl size="small" fullWidth>
                <InputLabel id="access">Access</InputLabel>
                <Select
                  variant="outlined"
                  className="select"
                  onChange={(event) =>
                    this.inputChange("access", event.target.value)
                  }
                  value={access}
                  label="Access"
                  margin="normal"
                >
                  {["energia", "telco", "dual"].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ mt: "20px" }}>
              <FormControl size="small" fullWidth>
                <InputLabel id="Pausa">Pausa</InputLabel>
                <Select
                  variant="outlined"
                  className="select"
                  onChange={(event) =>
                    this.inputChange("pauseTeam", event.target.value)
                  }
                  value={pauseTeam}
                  label="Pausa"
                  margin="normal"
                >
                  {["energia", "telco"].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ mt: "20px" }}>
              <FormControlLabel
                className={
                  elite
                    ? "-superUser defaultCheckbox--checked"
                    : "-superUser defaultCheckbox"
                }
                control={
                  <Checkbox
                    checked={elite}
                    onChange={(e) =>
                      this.inputChange("elite", e.target.checked)
                    }
                  />
                }
                label="Super User"
              />
            </Box>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.createUser} color="primary">
            Create new
          </Button>
        </DialogActions>
      </Dialog>
    ) : (
      <Dialog
        open={this.props.open}
        onClose={this.closePopup}
        aria-labelledby="form-dialog-title"
        classes={{
          paper: "visible-item",
        }}
      >
        <DialogTitle id="form-dialog-title">Edit user</DialogTitle>
        <DialogContent
          style={style.content}
          className="vissible-item user_popup_section"
        >
          <div className="fieldsGroup">
            <TextField
              label="Full name"
              variant="outlined"
              size="small"
              defaultValue={name}
              onChange={(event) => this.inputChange("name", event.target.value)}
            />
            <TextField
              label="Login name"
              variant="outlined"
              size="small"
              value={login}
              onChange={(event) =>
                this.inputChange("login", event.target.value)
              }
            />
          </div>
          <Box sx={{ mt: "20px" }}>
            <FormControl size="small" fullWidth>
              <InputLabel id="role">Role</InputLabel>
              <Select
                variant="outlined"
                className="select"
                onChange={(event) =>
                  this.inputChange("role", event.target.value)
                }
                value={role}
                label="Role"
                margin="normal"
              >
                {["admin", "user"].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ mt: "20px" }}>
            <FormControl size="small" fullWidth>
              <InputLabel id="access">Access</InputLabel>
              <Select
                variant="outlined"
                className="select"
                onChange={(event) =>
                  this.inputChange("access", event.target.value)
                }
                value={access}
                label="Access"
                margin="normal"
              >
                {["energia", "telco", "dual"].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ mt: "20px" }}>
              <FormControl size="small" fullWidth>
                <InputLabel id="Pausa">Pausa</InputLabel>
                <Select
                  variant="outlined"
                  className="select"
                  onChange={(event) =>
                    this.inputChange("pauseTeam", event.target.value)
                  }
                  value={pauseTeam}
                  label="Pausa"
                  margin="normal"
                >
                  {["energia", "telco"].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          <Box
            sx={{
              display: "flex",
              mt: "20px",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <FormControlLabel
                className={
                  elite
                    ? "-superUser defaultCheckbox--checked"
                    : "-superUser defaultCheckbox"
                }
                control={
                  <Checkbox
                    checked={elite}
                    onChange={(e) =>
                      this.inputChange("elite", e.target.checked)
                    }
                  />
                }
                label="Super User"
              />
            </Box>
            {/* <Box sx={{ ml: "20px" }}>
              <span style={{ color: "#9999ad", marginRight: "20px" }}>
                Generate password
              </span>
              <Tooltip title="Generate Password" placement="bottom-end">
                <Button
                  className=""
                  onClick={this.generatePass}
                  style={{ background: "#ecf1f9" }}
                  mini
                >
                  <Icon style={{ color: "#2B61A5" }} className="">
                    all_inclusive
                  </Icon>
                </Button>
              </Tooltip>
            </Box> */}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.updateUser} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default PopupUser;

const style = {
  content: {
    width: "550px",
    // height: '270px',
    minHeight: "270px",
    overflow: "visible",
  },
};

const Editor = {
  modules: {
    toolbar: [
      [{ header: [] }, { color: [] }], // , {'color': []}, {'background': []}
      ["bold", "italic", "underline", "blockquote"],
      [{ list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["link"],
      ["clean"],
    ],
  },

  formats: [
    "header",
    "color",
    // 'background',
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ],
};
